import React from "react"

import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PageContent from "../../page-views/materials/stainless-steel-and-duplex"
import BgImage from "../../assets/images/2017/09/pipe-stack-slide.jpg"

const StainlessSteelPage = () => {
  const title = "Stainless Steel and Duplex"
  const content = "Offshore oil and gas, chemical, water and food piping"

  return (
    <Layout>
      <Hero title={title} content={content} bgImage={BgImage} />
      <PageContent />
    </Layout>
  )
}

export default StainlessSteelPage
